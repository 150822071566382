/**
 * 获取token
 * @returns
 */
export function getToken() {
  return sessionStorage.getItem("ACCESS_TOKEN");
}
/**
 * 保存token
 * @param {*} token
 * @returns
 */
export function setToken(token) {
  return sessionStorage.setItem("ACCESS_TOKEN", token);
}
/**
 * 删除缓存
 * @returns
 */
export function removeToken() {
  sessionStorage.removeItem("LOGISTICS_USER");
  return sessionStorage.removeItem("ACCESS_TOKEN");
}
