import { getToken, setToken, removeToken } from "@/utils/common";
import { LoginFunc } from "@/api";

export default {
  state: {
    token: getToken(),
    user: sessionStorage.getItem("LOGISTICS_USER")
      ? JSON.parse(sessionStorage.getItem("LOGISTICS_USER"))
      : null,
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      if (user !== null && user !== undefined && user !== "") {
        state.user = JSON.stringify(user);
        sessionStorage.setItem("LOGISTICS_USER", JSON.stringify(user));
      }
    },
  },
  actions: {
    /**
     * 登录
     * @param commit
     * @param userInfo
     * @return {Promise<unknown>}
     * @constructor
     */
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        LoginFunc.login(userInfo)
          .then((res) => {
            setToken(res.data.token);
            commit("SET_TOKEN", res.data.token);
            setUserInfo(res.data, commit);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    /**
     * 登出
     * @param commit
     * @return {Promise<unknown>}
     * @constructor
     */
    LogOut({ commit }) {
      return new Promise((resolve) => {
        commit("SET_TOKEN", "");
        removeToken();
        resolve();
      });
    },
  },
};

export const setUserInfo = (res, commit) => {
  commit("SET_USER", res);
};
