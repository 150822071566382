import request from "@/utils/request.js";

/**
 * 订单处理，生成双物流
 */
export function dualHandler(data) {
  return request({
    url: "/api/Order/DualHandler",
    method: "post",
    data: data,
  });
}

/**
 * 订单处理记录
 */
export function orderLogListGet(params) {
  return request({
    url: "/api/Order/GetOrderListByPage",
    method: "get",
    params: params,
  });
}

/**
 * 订单发货
 */
export function deliverOrderByOid(params) {
  return request({
    url: "/api/Order/OrderDeliver",
    method: "get",
    params: params,
  });
}

export default {
  dualHandler,
  orderLogListGet,
  deliverOrderByOid,
};
