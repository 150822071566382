<template>
  <el-dialog
    v-model="dialogVisible"
    :title="isRecharge ? '账户充值' : '联系客服'"
    width="30%"
    top="30px"
    :before-close="handleClose"
  >
    <el-tabs v-model="activeName" class="demo-tabs">
      <el-tab-pane label="联系QQ" name="QQ">
        <div style="width: 100%; text-align: center">
          <el-image :src="require('@/assets/3556980460.png')" fit="cover" />
          <div style="font-size: 20px">联系客服QQ：3556980460</div>
        </div>
        <div
          style="color: red; font-size: 25px; text-align: center"
          v-if="isRecharge"
        >
          充值提示：充值请联系上面客服!!!
        </div>
      </el-tab-pane>
      <el-tab-pane label="联系微信" name="微信">
        <div style="width: 100%; height: 100%; text-align: center">
          <img
            style="width: 358px; height: 351px"
            :src="require('@/assets/weixin.jpg')"
          />
        </div>
        <div
          style="color: red; font-size: 25px; text-align: center"
          v-if="isRecharge"
        >
          充值提示：充值请联系上面客服!!!
        </div>
      </el-tab-pane>
    </el-tabs>
  </el-dialog>
</template>
<script setup>
import { ref, defineExpose } from "vue";

let dialogVisible = ref(false);
let isRecharge = ref(false);
let activeName = ref("QQ");

const handleClose = () => {
  dialogVisible.value = false;
};
const handleOpen = (isTrue) => {
  dialogVisible.value = true;
  isRecharge.value = isTrue;
};

defineExpose({
  handleOpen,
});
</script>
<style scoped lang="scss"></style>
