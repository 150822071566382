import { createRouter, createWebHistory } from "vue-router";
import Layout from "../views/layout/App.vue";

const routes = [
  {
    path: "/",
    name: "Dashboard",
    redirect: "/logistics",
    component: Layout,
    children: [
      {
        path: "logistics",
        name: "SyncLogistics",
        meta: {
          title: "双物流发货",
        },
        component: () => import("../views/zhaijisong/index.vue"),
      },
      {
        path: "billDetails",
        name: "BillDetails",
        meta: {
          title: "账单明细",
        },
        component: () => import("../views/bill/index.vue"),
      },
      {
        path: "orderLog",
        name: "OrderLog",
        meta: {
          title: "订单处理日志",
        },
        component: () => import("../views/deliver/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    meta: {
      title: "用户登录",
      keepAlive: false,
    },
    component: () => import("../views/login/index.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import("../components/404.vue"),
  },
  {
    path: "/:pathMatch(.*)",
    redirect: "/404",
  },
  {
    path: "/recharge",
    name: "Recharge",
    meta: {
      title: "用户充值",
      keepAlive: false,
    },
    component: () => import("../views/recharge/index.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
