import request from "@/utils/request.js";

/**
 * 消费明细
 */
export function consumeDetailsGet(params) {
  return request({
    url: "/api/User/BalanceDetailList",
    method: "get",
    params: params,
  });
}

export default {
  consumeDetailsGet,
};
