import request from "@/utils/request.js";

/**
 * 账号密码登录
 * @param params
 * @returns {AxiosPromise}
 */
export function login(params) {
  return request({
    url: "/api/User/Login",
    method: "get",
    params: params,
  });
}

/**
 * 注册
 */
export function registerUser(data) {
  return request({
    url: "/api/User/Register",
    method: "post",
    data: data,
  });
}

/**
 * 充值
 */
export function accountRecharge(data) {
  return request({
    url: "/api/User/Recharge",
    method: "post",
    data: data,
  });
}

/**
 * 查询用户信息
 */
export function getUserInfo(params) {
  return request({
    url: "/api/User/GetUserInfo",
    method: "get",
    params: params,
  });
}

/**
 * 充值
 */
export function alipayRecharge(params) {
  return request({
    url: "/api/Alipay/alipay",
    method: "get",
    params: params,
  });
}

export default {
  login,
  registerUser,
  accountRecharge,
  getUserInfo,
  alipayRecharge,
};
