<template>
  <div class="main">
    <div class="header">
      <div class="header-left">当前余额：{{ loginUser.balance }}</div>
      <div class="header-right">
        <div
          style="cursor: pointer; display: flex; align-items: center"
          @click="contactKf"
        >
          <el-icon><Service /></el-icon>联系客服
        </div>
        <div style="cursor: pointer; color: red" @click="rechargeFunc">
          充值
        </div>
        <div>
          <el-dropdown style="color: #ffffff">
            <span class="el-dropdown-link">
              {{ loginUser.mobile }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item @click.stop="updateLoginPwdFunc"
                  >修改密码</el-dropdown-item
                >
                <el-dropdown-item @click.stop="loginOut"
                  >退出登录</el-dropdown-item
                >
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="body">
      <!-- header -->
      <div class="left-menu">
        <el-menu
          :default-active="defaultPath"
          router
          class="el-menu-vertical-demo"
        >
          <el-menu-item index="/logistics">
            <el-icon><Van /></el-icon>
            <span>抖音双物流</span>
          </el-menu-item>
          <!-- <el-menu-item index="4">
            <el-icon><Present /></el-icon>
            <span>礼品单发货(抖音)</span>
          </el-menu-item> -->
          <el-menu-item index="/orderLog">
            <el-icon><Notebook /></el-icon>
            <span>订单处理日志</span>
          </el-menu-item>
          <el-menu-item index="/billDetails">
            <el-icon><Collection /></el-icon>
            <span>充值消费明细</span>
          </el-menu-item>
        </el-menu>
      </div>
      <div class="middle"></div>
      <div class="right-content">
        <router-view />
      </div>
    </div>
    <!-- 联系客服 -->
    <Customer ref="kefuRef" />
    <!-- 充值 -->
    <RechargeView ref="rechargeRef" />
  </div>
</template>
<script setup>
import { ref, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { LoginFunc } from "@/api";
import Customer from "@/components/customer.vue";
import RechargeView from "@/components/Recharge.vue";
const $store = useStore();
let loginUser = reactive({
  id: null,
  mobile: "",
  balance: 0,
});

const defaultPath = ref("/logistics");
/******************** 函数 *********************/
onMounted(() => {
  defaultPath.value = window.location.pathname;
  LoginFunc.getUserInfo()
    .then((res) => {
      if (res.code === 200) {
        loginUser.id = res.data.id;
        loginUser.mobile = res.data.mobile;
        loginUser.balance = res.data.balance;
      }
    })
    .catch(() => {});
});
const loginOut = () => {
  $store
    .dispatch("LogOut")
    .then(() => {
      window.location.href = "/login";
    })
    .catch(() => {});
};
const updateLoginPwdFunc = () => {
  console.log("修改登录密码");
};
const kefuRef = ref("");
const contactKf = () => {
  kefuRef.value.handleOpen(false);
};
const rechargeRef = ref("");
const rechargeFunc = () => {
  rechargeRef.value.handleOpen(true);
};
</script>
<style lang="scss">
@import "../../assets/css/variables.scss";
.main {
  width: 100vw;
  .header {
    width: 100%;
    height: 50px;
    background: #2892ff;
    color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    .header-left {
      width: 85%;
      height: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 30px;
    }
    .header-right {
      width: 15%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 25px;
    }
  }
  .body {
    position: relative;
    height: calc(100vh - 50px);
    transition: margin-left 0.3s ease-in-out;
    display: flex;
    justify-content: space-between;
    padding: 20px 20px 20px 0;
    background: #f0f2f5;
    .left-menu {
      width: 248px;
      height: 100%;
      left: 0;
      background: #ffffff;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;
      .el-menu-vertical-demo {
        border-top-right-radius: 15px;
        li:first-child {
          border-top-right-radius: 15px;
        }
      }
      .is-active {
        background: var(--el-menu-active-color);
        color: #ffffff !important;
      }
    }
    .middle {
      width: 15px;
      height: 100%;
    }
    .right-content {
      width: calc(100vw - 258px);
      height: 100%;
      background: #ffffff;
      padding: 8px;
      border-radius: 15px;
    }
  }
}
</style>
