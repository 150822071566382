<template>
  <div class="pagination">
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageParams.pageNum"
      :page-sizes="pageSizes"
      :page-size="pageParams.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
export default {
  name: "Pagination",
  props: {
    requestFunc: [Function, Boolean],
    filterParams: {
      type: Function,
      default: (p) => {
        return p;
      },
    },
    params: Object,
    paginationField: {
      type: String,
      default: "",
    },
  },
  data: () => {
    return {
      total: 0,
      pageSizes: [10, 20, 50, 100],
      pageParams: {
        pageSize: 10,
        pageNum: 1,
      },
    };
  },
  methods: {
    handleSizeChange(val) {
      this.pageParams.pageSize = val;
      this.getPageData();
    },
    handleCurrentChange(val) {
      this.pageParams.pageNum = val;
      this.getPageData();
    },
    getPageData() {
      if (!this.requestFunc) {
        this.$emit("returnData", []);
        return;
      }
      let p = this.filterParams({ ...this.params, ...this.pageParams });
      this.requestFunc(p)
        .then((result) => {
          let r = result;
          if (this.paginationField) {
            r = result[this.paginationField];
          }
          this.total = parseInt(r.totalCount);
          this.$emit("returnData", result.data);
        })
        .catch(() => {});
    },
    Refresh() {
      this.pageParams.pageNum = 1;
      this.getPageData();
    },
    GetAllParam() {
      return { ...this.params, ...this.pageParams };
    },
  },
  mounted: function () {
    this.getPageData();
  },
};
</script>

<style scoped>
.pagination {
  padding: 10px;
  display: flex;
  background: #fff;
}
</style>
