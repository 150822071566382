<template>
  <el-dialog
    v-model="dialogVisible"
    :title="'账户充值'"
    width="70%"
    :before-close="handleClose"
  >
    <div class="container">
      <div
        class="item"
        :class="item.id === selectedId ? 'active' : ''"
        v-for="item in list"
        :key="item.id"
        @click="checkItem(item.id)"
      >
        <div class="item-payment">￥{{ item.price }}</div>
        <a-divider style="margin: 0" />
        <div class="item-text">
          <span style="color: #9cafc5">约发</span>
          <span style="color: #5e6485">{{
            Number(item.price / 1.2).toFixed(0)
          }}</span>
          <span style="color: #9cafc5">单</span>
        </div>
        <!-- <div class="item-price">
          <span style="color: #9cafc5">单价</span>
          <span style="color: #5e6485">{{ item.unit }}</span>
          <span style="color: #9cafc5">元/条</span>
        </div> -->
      </div>
    </div>
    <div
      style="
        width: 100%;
        min-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
      "
    >
      <el-button type="primary" @click="toRecharge">立即充值</el-button>
    </div>
    <!-- 支付宝充值页面 -->
    <div ref="aliPayRefs" v-html="aliPayHtml"></div>
  </el-dialog>
</template>
<script setup>
import { ref, defineExpose, reactive } from "vue";
import { ElMessage } from "element-plus";
import { LoginFunc } from "@/api";

/***************************** 变量 ******************************/
let selectedId = ref(1);
let aliPayHtml = ref("");
let list = reactive([
  {
    id: 1,
    price: 20,
    smsNum: 200,
    unit: "0.10",
    text: "单价0.10元/条,共200条,总计20元",
  },
  {
    id: 2,
    price: 50,
    smsNum: 500,
    unit: "0.10",
    text: "单价0.10元/条,共500条,总计50元",
  },
  {
    id: 3,
    price: 100,
    smsNum: 1000,
    unit: "0.10",
    text: "单价0.10元/条,共1000条,总计100元",
  },
  {
    id: 4,
    price: 200,
    smsNum: 2000,
    unit: "0.10",
    text: "单价0.10元/条,共2000条,总计200元",
  },
  {
    id: 5,
    price: 500,
    smsNum: 5000,
    unit: "0.10",
    text: "单价0.10元/条,共5000条,总计500元",
  },
]);
let dialogVisible = ref(false);
/***************************** 函数 ******************************/
const checkItem = (v) => {
  selectedId.value = v;
};
const aliPayRefs = ref("");
const toRecharge = () => {
  let row = list.find((r) => {
    return r.id == selectedId.value;
  });
  LoginFunc.alipayRecharge({ amount: row.price })
    .then((res) => {
      if (res.code == 200) {
        let aliPayHtm =
          res.data.substring(0, 5) + ' target="_blank"' + res.data.substring(5);
        aliPayHtml.value = aliPayHtm;
        setTimeout(() => {
          aliPayRefs.value.children[0].submit();
        }, 50);
      } else {
        ElMessage.error(res.message);
      }
    })
    .catch(() => {});
};
const handleClose = () => {
  dialogVisible.value = false;
};
const handleOpen = () => {
  dialogVisible.value = true;
};
defineExpose({
  handleOpen,
});
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  min-height: 150px;
  padding: 10px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .item {
    width: 180px;
    height: 200px;
    padding: 6px;
    border: 1px solid #dcdfe6;
    cursor: pointer;
    .item-payment {
      width: 100%;
      height: 50%;
      font-size: 30px;
      color: #fd7918;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item-text {
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .item-price {
      width: 100%;
      height: 25%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .active {
    border: 2px solid #0086f1;
  }
}
</style>
