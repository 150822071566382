import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "@/assets/css/style.scss";
import moment from "moment";
import { getToken } from "@/utils/common";
import Pagination from "@/components/Pagination.vue";

// 路由前置过滤;
router.beforeEach((to, from, next) => {
  window.document.title = to.meta.title ? to.meta.title : "发货网";
  if (!getToken() && to.path !== "/login" && to.path !== "/recharge") {
    next({ path: "/login" });
  } else {
    next();
  }
});

const app = createApp(App);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.component("Pagination", Pagination);
app.config.globalProperties.$moment = moment;
app.use(store).use(router).use(ElementPlus, { locale: zhCn, zIndex: 3000 });
app.mount("#app");
