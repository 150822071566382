import Axios from "axios";
import store from "@/store";
import { ElMessage, ElMessageBox } from "element-plus";
import { getToken } from "@/utils/common";

const service = Axios.create({
  headers: {
    Accept: "*/*",
  },
  timeout: 60000,
});
service.defaults.retry = 4;
service.defaults.retryDelay = 800;

service.interceptors.request.use(
  (config) => {
    // 携带token
    config.headers["Authorization"] = "Bearer " + getToken();
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

service.interceptors.response.use(
  (res) => {
    if (res.status !== 200) {
      ElMessage({
        message: "服务器异常,请联系客服!",
        type: "error",
      });
      return Promise.reject("error");
    } else {
      if (res.data.code === 500) {
        if (
          res.data.message !== "" &&
          res.data.message !== null &&
          res.data.message !== undefined
        ) {
          ElMessage({
            message: res.data.message,
            type: "error",
          });
        } else {
          ElMessage({
            message: "服务器异常,请联系客服!",
            type: "error",
          });
        }
        //return Promise.reject(res.data.message);
      } else if (res.data.code < 500 && res.data.code > 401) {
        ElMessage({
          message: res.data.message,
          type: "error",
        });
        // return Promise.reject("error");
        //return Promise.reject(res.data.message);
      } else if (res.data.code === 400) {
        ElMessage({
          message: res.data.message,
          type: "error",
        });
        // return Promise.reject("error");
        //return Promise.reject(res.data.message);
      } else if (res.data.code === 401) {
        ElMessageBox.confirm(
          "登录状态已过期，您可以继续留在该页面，或者重新登录",
          "系统提示",
          {
            confirmButtonText: "重新登录",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).then(() => {
          store.dispatch("LogOut").then(() => {
            location.reload(); // 为了重新实例化vue-router对象 避免bug
          });
        });
      }
      return res.data;
    }
  },
  (error) => {
    let code = error.response.status;
    if (code === 401) {
      ElMessageBox.confirm(
        "登录状态已过期，您可以继续留在该页面，或者重新登录",
        "系统提示",
        {
          confirmButtonText: "重新登录",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        store.dispatch("LogOut").then(() => {
          location.reload(); // 为了重新实例化vue-router对象 避免bug
        });
      });
    } else if (code === 403) {
      ElMessage({
        message: "请求参数校验失败,请重试!",
        type: "error",
      });
    } else {
      ElMessage({
        message: "服务器可能出了点问题,请联系客服!",
        type: "error",
      });
    }
    return Promise.reject(error);
  }
);

export default service;
